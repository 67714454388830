import { gql } from '@apollo/client'

const GET_INFINITE_SCROLL_NEWS = gql`
  query GET_INFINITE_SCROLL_NEWS(
    $types: [NewsType!]
    $pageSize: Int
    $page: Int
    $dateFrom: DateTime
    $dateTo: DateTime
    $areaId: ID
  ) {
    news(
      limit: $pageSize
      pageNumber: $page
      types: $types
      dateFrom: $dateFrom
      dateTo: $dateTo
      areaId: $areaId
    ) {
      id
      live
      headline
      date
      imageUrl
      type
      title
      blurb
      contentUrl
    }
  }
`
export default GET_INFINITE_SCROLL_NEWS
