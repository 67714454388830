import PropTypes, { InferProps } from 'prop-types'
import { Box, Grid, Column } from '@resident-advisor/design-system'
import arrayHasData from 'lib/arrayHasData'
import LatestNewsListing from 'components/editorial/sub-sections/latest-news-listing'
import PopularItemsVertical from 'components/editorial/sub-sections/popular-items-vertical'
import PopularNewsCard from 'components/editorial/cards/popular-news'
import magazineMessages from 'messages/magazine'
import { useIntl } from 'react-intl'
import PLAYWRIGHT_TEST_IDS from 'enums/playwright-test-ids'
import NEWS_TYPES from 'enums/news-types'

const getNewsCategoryLink = (type) => {
  if (type === NEWS_TYPES.mixOfTheDay) {
    return 'mix-of-the-day'
  }

  return `news?category=${type.toLowerCase()}`
}

const Areas = {
  Latest: 'latest',
  Popular: 'popular',
}

const LAYOUTS = {
  popularWrapBottom: {
    s: `
      "latest"
      "popular"
    `,
    m: `
      "latest latest popular"
    `,
  },
  standard: {
    s: `
      "popular"
      "latest"
    `,
    m: `
      "latest latest popular"
    `,
  },
}

const NewsSectionMarkup = ({
  popularNews,
  latestNews,
  headlineNews,
  layout,
  AdComponent,
}: NewsSectionMarkupProps) => {
  const intl = useIntl()

  return (
    <Grid
      sCols={1}
      mCols={3}
      gridTemplateAreas={layout}
      data-pw-test-id={PLAYWRIGHT_TEST_IDS.newsSection}
    >
      {(arrayHasData(headlineNews) || arrayHasData(latestNews)) && (
        <Column area={Areas.Latest}>
          <LatestNewsListing
            headlineNews={headlineNews}
            latestNews={latestNews}
            formatTypeUrl={getNewsCategoryLink}
          />
        </Column>
      )}
      {arrayHasData(popularNews) && (
        <Column area={Areas.Popular}>
          <Box mb={4}>
            <PopularItemsVertical
              Card={PopularNewsCard}
              data={popularNews}
              title={intl.formatMessage(magazineMessages.popularNews)}
            />
            {AdComponent}
          </Box>
        </Column>
      )}
    </Grid>
  )
}

const newsArrayPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
  })
)

const propTypes = {
  popularNews: newsArrayPropType,
  latestNews: newsArrayPropType,
  headlineNews: newsArrayPropType,
  layout: PropTypes.oneOf(Object.values(LAYOUTS)),
  AdComponent: PropTypes.element,
}

NewsSectionMarkup.propTypes = propTypes

NewsSectionMarkup.defaultProps = {
  popularNews: [],
  latestNews: [],
  headlineNews: [],
  layout: LAYOUTS.standard,
}

type NewsSectionMarkupProps = InferProps<typeof propTypes>

export { getNewsCategoryLink, LAYOUTS }
export default NewsSectionMarkup
