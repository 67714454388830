import PropTypes from 'prop-types'
import { Alignment, variants } from '@resident-advisor/design-system'
import ResponsiveImage from 'components/generic/responsive-image'
import CmsContent from 'components/generic/cms-content'
import Link from 'components/generic/link'
import Date from 'components/generic/date'
import isToday from 'date-fns/isToday'
import ASPECT_RATIO from 'lib/aspectRatio'
import unescape from 'lodash/unescape'
import { toDateFormat } from 'lib/dateFormat'

const BasicCard = ({
  imageUrl,
  contentUrl,
  date,
  title,
  blurb,
  isFeatured,
}) => (
  <Alignment flexDirection="column">
    {imageUrl && (
      <Link href={contentUrl} noStyle>
        <ResponsiveImage
          aspect={ASPECT_RATIO['16:9']}
          url={imageUrl}
          alt={title}
          sizes={{ m: '33vw' }}
        />
      </Link>
    )}
    {date && (
      <Date
        color="secondary"
        format={
          isToday(toDateFormat(date))
            ? Date.Formats.Time
            : Date.Formats.FullDateAndTime
        }
        date={date}
        variant={variants.text.heading.s}
        mt={4}
      />
    )}
    <Link
      variant={isFeatured ? variants.text.heading.l : variants.text.heading.m}
      mt={2}
      href={contentUrl}
    >
      {unescape(title)}
    </Link>
    <CmsContent color="primary" mt={2} content={blurb} />
  </Alignment>
)

BasicCard.propTypes = {
  imageUrl: PropTypes.string,
  contentUrl: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  blurb: PropTypes.string,
  isFeatured: PropTypes.bool,
}

export default BasicCard
