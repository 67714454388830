import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Lazy } from '@resident-advisor/design-system'
import ASPECT_RATIO from 'lib/aspectRatio'

const Player = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const YoutubePlayer = ({ youtubeId }) => (
  <Lazy aspect={ASPECT_RATIO['16:9']}>
    <Player
      title="Youtube Embed"
      id="ytplayer"
      type="text/html"
      src={`https://www.youtube.com/embed/${youtubeId}`}
      frameBorder="0"
      allowFullScreen
    />
  </Lazy>
)

YoutubePlayer.propTypes = {
  youtubeId: PropTypes.string.isRequired,
}

export default YoutubePlayer
