import { gql } from '@apollo/client'

const NEWS_FRAGMENT = gql`
  fragment newsFields on News {
    id
    type
    headline
    imageUrl
    date
    title
    blurb
    contentUrl
  }
`

const GET_LATEST_NEWS = gql`
  query GET_LATEST_NEWS(
    $latestNewsFilters: FilterInputDtoInput
    $headlineNewsFilters: FilterInputDtoInput
  ) {
    latestNews: facetedSearch(
      types: [NEWS]
      filters: $latestNewsFilters
      page: 1
      pageSize: 9
      sort: { date: { order: DESCENDING } }
    ) {
      totalResults
      results {
        id
        data {
          ... on News {
            ...newsFields
          }
        }
      }
    }
    headlineNews: facetedSearch(
      types: [NEWS]
      filters: $headlineNewsFilters
      page: 1
      pageSize: 1
      sort: { date: { order: DESCENDING } }
    ) {
      totalResults
      results {
        id
        data {
          ... on News {
            ...newsFields
          }
        }
      }
    }
  }
  ${NEWS_FRAGMENT}
`

const GET_POPULAR_NEWS = gql`
  query GET_POPULAR_NEWS($filters: FilterInputDtoInput) {
    popularNews: facetedSearch(
      types: [NEWS]
      filters: $filters
      page: 1
      pageSize: 9
      sort: { newsViews: { order: DESCENDING } }
    ) {
      totalResults
      results {
        id
        data {
          ... on News {
            ...newsFields
          }
        }
      }
    }
  }
  ${NEWS_FRAGMENT}
`

export { GET_LATEST_NEWS, GET_POPULAR_NEWS }
