import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import useQueryWithPagination from 'hooks/useQueryWithPagination'
import {
  SubSection,
  Section,
  variants,
  Grid,
  Column,
} from '@resident-advisor/design-system'
import Padded from 'components/generic/padded'
import GroupedNews from 'components/editorial/sections/news-listing/GroupedNews'
import { groupDataByDateInterval } from 'lib/dataGrouper'
import ViewMoreButton from 'components/generic/view-more-button'
import messages from 'messages/magazine'
import dict from 'messages/dict'
import InfiniteScrollQueryResultHandler from 'components/generic/infinite-scroll-query-result-handler'
import getConfig from 'next/config'
import NEWS_TYPES from 'enums/news-types'
import { usePersonalizationContext } from 'context/PersonalizationContext'
import GET_INFINITE_SCROLL_NEWS from './GetInfiniteScrollNewsQuery'

const {
  publicRuntimeConfig: { INFINITE_SCROLL_CONFIG },
} = getConfig()

const InfiniteScrollNewsListing = ({
  dateFrom,
  dateTo,
  skip,
  groupDataInterval,
  variant = 'primary',
  newsTypes = [
    NEWS_TYPES.festival,
    NEWS_TYPES.raOnRa,
    NEWS_TYPES.tech,
    NEWS_TYPES.magazine,
    NEWS_TYPES.local,
  ],
  viewMoreHref = '/news',
}) => {
  const intl = useIntl()
  const { loading: areaLoading, area } = usePersonalizationContext()

  const queryResult = useQueryWithPagination(GET_INFINITE_SCROLL_NEWS, {
    variables: {
      types: newsTypes,
      pageSize: parseInt(INFINITE_SCROLL_CONFIG.INFINITE_SCROLL_PAGE_SIZE),
      page: 1,
      dateFrom,
      dateTo,
      areaId: area?.id,
    },
    dataKey: 'news',
    maxPages: parseInt(INFINITE_SCROLL_CONFIG.INFINITE_SCROLL_MAX_PAGES),
  })

  const formatTypeUrl = (type) => `news?category=${type.toLowerCase()}`

  return (
    <Section title={intl.formatMessage(dict.news)} variant={variant}>
      <Padded>
        <SubSection variant={variant}>
          <Grid sCols={1} mCols={3}>
            <Column mSpan={2}>
              <InfiniteScrollQueryResultHandler
                {...queryResult}
                loading={queryResult.loading || areaLoading}
                dataKey="news"
                formatTypeUrl={formatTypeUrl}
                markup={InfiniteScrollNewsListingMarkup}
                skip={skip}
                variant={variant}
                groupDataInterval={groupDataInterval}
              />
              {queryResult?.finishedPaginating && (
                <ViewMoreButton
                  href={viewMoreHref}
                  variant={variants.button.secondary}
                >
                  {intl.formatMessage(messages.viewMoreNews)}
                </ViewMoreButton>
              )}
            </Column>
          </Grid>
        </SubSection>
      </Padded>
    </Section>
  )
}

InfiniteScrollNewsListing.propTypes = {
  dateFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  dateTo: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  skip: PropTypes.number,
  variant: PropTypes.string,
  bg: PropTypes.string,
  newsTypes: PropTypes.arrayOf(PropTypes.string),
  viewMoreHref: PropTypes.string,
  groupDataInterval: PropTypes.oneOf(
    Object.values(groupDataByDateInterval.Intervals)
  ),
}

const InfiniteScrollNewsListingMarkup = ({
  data,
  skip,
  groupDataInterval,
  ...props
}) => (
  <GroupedNews
    newsItems={skip ? data.slice(skip) : data}
    groupDataInterval={groupDataInterval}
    {...props}
  />
)

InfiniteScrollNewsListingMarkup.propTypes = {
  data: PropTypes.array.isRequired,
  skip: PropTypes.number,
  groupDataInterval: PropTypes.oneOf(
    Object.values(groupDataByDateInterval.Intervals)
  ),
}

InfiniteScrollNewsListingMarkup.defaultProps = {
  groupDataInterval: groupDataByDateInterval.Intervals.DayAndFullDate,
}

export { InfiniteScrollNewsListingMarkup }
export default InfiniteScrollNewsListing
