import { gql } from '@apollo/client'

const GET_FEATURES = gql`
  query GET_FEATURES(
    $orderBy: OrderByType
    $count: Int
    $type: FeatureType
    $dateFrom: DateTime
    $dateTo: DateTime
    $excludeIds: [ID]
  ) {
    features(
      orderBy: $orderBy
      limit: $count
      type: $type
      dateFrom: $dateFrom
      dateTo: $dateTo
      excludeIds: $excludeIds
    ) {
      id
      title
      blurb
      date
      images(type: MOBILE) {
        imageUrl
      }
      imageListingUrl
      contentUrl
      author {
        id
        name
        imageUrl
        username
        contributor
      }
      series {
        id
        seriesRef
        title
        contentUrl
      }
      featured
      youtubeId
    }
  }
`

export default GET_FEATURES
