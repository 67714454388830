import PropTypes from 'prop-types'
import {
  DefaultLoadingSpinner,
  useQueryResult,
} from 'components/generic/query-result-handler'
import { usePersonalizationContext } from 'context/PersonalizationContext'
import { NewsType } from 'enums/news-types'
import { NewsLocalityType } from 'enums/listing'
import FilterInputDto from 'interfaces/gql/FilterInputDto'
import get from 'lodash/get'
import addDays from 'date-fns/addDays'
import { useServerTime } from 'context/ServerTimeContext'
import { format } from 'date-fns'
import arrayHasData from 'lib/arrayHasData'
import { GET_LATEST_NEWS, GET_POPULAR_NEWS } from './GetNewsQuery'
import NewsSectionMarkup, { LAYOUTS } from './NewsSectionMarkup'

const NEWS_LOCALITY_TYPES = [
  NewsLocalityType.Regional,
  NewsLocalityType.Countrywide,
]

const NEWS_TYPES = [
  NewsType.RAOnRA,
  NewsType.Tech,
  NewsType.Magazine,
  NewsType.Festival,
  NewsType.Playlist,
  NewsType.MixOfTheDay,
]

const flattenResults = (data) =>
  arrayHasData(data)
    ? data?.filter((article) => article.data)?.map((article) => article.data)
    : []

const NewsSection = ({ layout, AdComponent }) => {
  const { loading: areaLoading, area } = usePersonalizationContext()

  const baseFilters: FilterInputDto = {
    // Either get local news filtered by area ID
    // OR get non-local news
    any: {
      all: {
        areas: { eq: parseInt(area?.id, 10) },
        newsType: { eq: NewsType.Local },
        newsLocalityType: { any: NEWS_LOCALITY_TYPES },
      },
      newsType: { any: NEWS_TYPES },
    },
  }
  const headlineNewsFilters: FilterInputDto = {
    ...baseFilters,
    newsHeadline: { eq: true },
  }

  const latestNewsDataKey = 'latestNews.results'
  const headlineNewsDataKey = 'headlineNews.results'
  const { data, loading, error } = useQueryResult(GET_LATEST_NEWS, {
    variables: {
      latestNewsFilters: baseFilters,
      headlineNewsFilters,
    },
    dataKey: [latestNewsDataKey, headlineNewsDataKey],
    skip: areaLoading,
  })

  if (error) {
    return null
  }

  if (areaLoading || loading) {
    return <DefaultLoadingSpinner />
  }

  const headlineNews = flattenResults(get(data, headlineNewsDataKey))
  const latestNews = flattenResults(get(data, latestNewsDataKey))

  return (
    <PopularNewsQueryHandler
      baseFilters={baseFilters}
      headlineNews={headlineNews}
      latestNews={latestNews}
      layout={layout}
      AdComponent={AdComponent}
    />
  )
}

NewsSection.propTypes = {
  layout: PropTypes.oneOf(Object.values(LAYOUTS)),
  AdComponent: PropTypes.element,
}

const PopularNewsQueryHandler = ({
  baseFilters,
  headlineNews,
  latestNews,
  layout,
  AdComponent,
}) => {
  const dateRange = useDateRange()

  const excludeIds = [
    ...headlineNews.map((x) => x.id),
    ...latestNews.map((x) => x.id),
  ]

  const popularNewsFilters: FilterInputDto = {
    ...baseFilters,
    id: {
      none: excludeIds,
    },
    date: {
      gte: dateRange.dateFrom,
      lte: dateRange.dateTo,
    },
  }
  const popularNewsDataKey = 'popularNews.results'

  const { data, loading, error } = useQueryResult(GET_POPULAR_NEWS, {
    variables: {
      filters: popularNewsFilters,
    },
    dataKey: popularNewsDataKey,
  })

  if (error) {
    return null
  }

  if (loading) {
    return <DefaultLoadingSpinner />
  }

  const popularNews = flattenResults(data)

  return (
    <NewsSectionMarkup
      popularNews={popularNews}
      latestNews={latestNews}
      headlineNews={headlineNews}
      layout={layout}
      AdComponent={AdComponent}
    />
  )
}

const useDateRange = () => {
  const serverTime = useServerTime()

  return {
    dateFrom: format(addDays(serverTime, -7), 'yyyy-MM-dd'),
    dateTo: format(serverTime, 'yyyy-MM-dd'),
  }
}

const newsArrayPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
  })
)

PopularNewsQueryHandler.propTypes = {
  baseFilters: PropTypes.shape({}),
  latestNews: newsArrayPropType,
  headlineNews: newsArrayPropType,
  layout: PropTypes.oneOf(Object.values(LAYOUTS)),
  AdComponent: PropTypes.element,
}

PopularNewsQueryHandler.defaultProps = {
  latestNews: [],
  headlineNews: [],
}

NewsSection.Layouts = LAYOUTS
export default NewsSection
