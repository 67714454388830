const NEWS_TYPES = {
  festival: 'FESTIVAL',
  general: 'GENERAL',
  international: 'INTERNATIONAL',
  local: 'LOCAL',
  magazine: 'MAGAZINE',
  mixOfTheDay: 'MIXOFTHEDAY',
  playlist: 'PLAYLIST',
  raOnRa: 'RAONRA',
  raPro: 'RAPRO',
  tech: 'TECH',
}

enum NewsType {
  RAOnRA = 1,
  RAPro = 2,
  Local = 4,
  International = 8,
  General = 9,
  Tech = 10,
  Magazine = 11,
  Festival = 12,
  Playlist = 13,
  MixOfTheDay = 14,
}

export { NewsType }
export default NEWS_TYPES
